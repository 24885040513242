<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Manage School Pay
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Manage School Pay
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Name" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Search by phone" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Email" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Contact person name" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Contact person email" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <button class="btn btn-primary"> Search</button>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">School name</th>
                  <th class="px-3">Full address</th>
                  <th class="px-3">Primary phone</th>
                  <th class="px-3">Email</th>
                  <th class="px-3">Finance person name</th>
                  <th class="px-3">Finance person phone</th>
                  <th class="px-3">Reason</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr>
                    <td class="px-2">
                      <a class=""
                      >Lorem ipsum dolor.</a>
                    </td>
                    <td>
                     <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
                     >NSW </span>
                    </td>
                    <td>
                     <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
                     >4423521</span>
                    </td>
                    <td>
                     <span class=""
                     >lorem@gmail.com</span>
                    </td>
                    <td>
                      <span class=""
                      >John Doe</span>
                    </td>
                    <td>
                      <span class="text-dark font-weight-bolder d-block font-size-lg"
                      >David Beckham</span>
                    </td>
                    <td>
                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Lorem ipsum dolor sit.
                      </span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editInvoice" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>

export default {
  data(){
    return{
    }
  },
  methods: {
    editInvoice(){

    }
  }
}
</script>
